<template>
  <v-container fluid class="white-background main-create-page pre-quotation-create-page" style="position: relative">
    <v-layout>
      <v-flex>
        <h1 class="form-title text-truncate">
          {{ get_title(true) }} For
          <template v-if="prospect">{{ prospect.first_name }}</template>
          <span
            class="form-title-create-link"
            link
            v-if="!lodash.isEmpty(customer)"
            >{{ customer.display_name }}</span
          >
        </h1>
      </v-flex>
    </v-layout>
    <v-form
      ref="quotation_form"
      v-model="form_status"
      lazy-validation
      v-on:submit.stop.prevent="update_or_create('quote')"
    >
      <v-layout class="mx-8 my-4">
        <v-flex md7 sm7>
          <table width="100%">
            <tr>
              <td>
                <label
                  for="quotation-title"
                  class="required font-weight-600 font-size-16"
                  >{{ get_title() }} Title</label
                >
              </td>
            </tr>
            <tr>
              <td>
                <v-text-field
                  id="quotation-title"
                  dense
                  filled
                  :disabled="form_loading"
                  :loading="form_loading"
                  color="cyan"
                  :label="`${get_title()} Title`"
                  solo
                  flat
                  v-model="quotation.title"
                  :rules="[
                    validateRules.required(
                      quotation.title,
                      `${get_title()} Title`
                    ),
                  ]"
                ></v-text-field>
              </td>
            </tr>
            <tr>
              <td>
                <label
                  for="quotation-description"
                  class="font-weight-600 font-size-16"
                  >Description</label
                >
              </td>
            </tr>
            <tr>
              <td>
                <v-textarea
                  id="quotation-description"
                  auto-grow
                  dense
                  filled
                  :disabled="form_loading"
                  :loading="form_loading"
                  color="cyan"
                  label="Description"
                  solo
                  flat
                  row-height="25"
                  v-model="quotation.description"
                ></v-textarea>
              </td>
            </tr>
          </table>
          <v-layout>
            <v-flex v-if="false" md4>
              <label class="font-size-16 font-weight-600"
                >Service Address&nbsp;&nbsp;&nbsp;<v-icon
                  v-if="false"
                  color="cyan"
                  small
                  >mdi-pencil</v-icon
                ></label
              >
              <p class="font-size-16 m-0">
                <template v-if="!lodash.isEmpty(property)">{{
                  property.property_address
                }}</template>
              </p>
            </v-flex>
            <template v-if="!lodash.isEmpty(billing)">
              <v-flex class="mr-2">
                <label class="font-size-16 font-weight-600"
                  >Site Address&nbsp;&nbsp;&nbsp;<v-icon
                    v-if="false"
                    color="cyan"
                    small
                    >mdi-pencil</v-icon
                  ></label
                >
                <p class="font-size-16 m-0">
                  {{ billing.property_address }}
                </p>
              </v-flex>
            </template>
            <template v-if="!lodash.isEmpty(contact_person)">
              <v-flex class="mx-2">
                <label class="font-size-16 font-weight-600"
                  >Contact Person&nbsp;&nbsp;&nbsp;<v-icon
                    v-if="false"
                    color="cyan"
                    small
                    >mdi-pencil</v-icon
                  ></label
                >
                <p class="font-size-16 m-0">{{ contact_person.full_name }}</p>
                <p class="font-size-16 m-0">
                  {{ contact_person.primary_phone }}
                </p>
                <p class="font-size-16 m-0">
                  {{ contact_person.primary_email }}
                </p>
              </v-flex>
            </template>
            <template v-if="!lodash.isEmpty(new_contact_person)">
              <v-flex class="ml-2">
                <label class="font-size-16 font-weight-600"
                  >Contact Person&nbsp;&nbsp;&nbsp;<v-icon
                    v-if="false"
                    color="cyan"
                    small
                    >mdi-pencil</v-icon
                  ></label
                >
                <p class="font-size-16 m-0">
                  {{ new_contact_person.full_name }}
                </p>
                <p class="font-size-16 m-0">
                  {{ new_contact_person.primary_phone }}
                </p>
                <p class="font-size-16 m-0">
                  {{ new_contact_person.primary_email }}
                </p>
              </v-flex>
            </template>
          </v-layout>
        </v-flex>
        <v-flex md5 sm5 class="ml-10">
          <table width="100%">
            <tr>
              <td colspan="2" class="custom-border-bottom">
                <label class="font-weight-600 font-size-16"
                  >{{ get_title(true) }} Details</label
                >
              </td>
            </tr>
            <tr>
              <td
                width="40%"
                height="50px"
                class="font-weight-500 font-size-16"
              >
                {{ get_title(true) }} number
              </td>
              <td
                width="60%"
                height="50px"
                class="font-weight-600 font-size-16"
              >
                <template v-if="quotation.barcode">{{
                  quotation.barcode
                }}</template>
                <template v-else>{{ barcode }}</template>
              </td>
            </tr>
            <tr>
              <td
                width="40%"
                height="50px"
                class="font-weight-500 font-size-16"
              >
                {{ get_title(true) }} Date
              </td>
              <td
                width="60%"
                height="50px"
                class="font-weight-600 font-size-16"
              >
                <date-picker
                  solo
                  v-model="quotation.date"
                  :min-date="today_date"
                  :default-date="quotation.date"
                ></date-picker>
              </td>
            </tr>
            <tr v-if="false">
              <td
                width="40%"
                height="50px"
                class="font-weight-500 font-size-16"
              >
                Rate opportunity
              </td>
              <td
                width="60%"
                height="50px"
                class="font-weight-600 font-size-16"
              >
                <v-rating
                  background-color="orange lighten-3"
                  color="orange"
                  :disabled="form_loading"
                  v-model="quotation.rating"
                ></v-rating>
              </td>
            </tr>
            <tr>
              <td
                width="40%"
                height="50px"
                class="font-weight-500 font-size-16"
              >
                Reference #
              </td>
              <td
                width="60%"
                height="50px"
                class="font-weight-600 font-size-16"
              >
                <v-text-field
                  dense
                  filled
                  color="cyan"
                  label="Reference #"
                  solo
                  hide-details
                  flat
                  :disabled="form_loading"
                  :loading="form_loading"
                  v-model="quotation.reference"
                ></v-text-field>
              </td>
            </tr>
            <tr v-if="isPackage">
              <td
                width="40%"
                height="50px"
                class="font-weight-500 font-size-16"
              >
                Project
              </td>
              <td
                width="60%"
                height="50px"
                class="font-weight-600 font-size-16"
              >
                <v-autocomplete
                  dense
                  color="cyan"
                  filled
                  label="Project"
                  solo
                  flat
                  item-color="cyan"
                  item-text="name"
                  item-value="id"
                  hide-details
                  :disabled="form_loading"
                  :loading="form_loading"
                  :items="project_list"
                  v-model="quotation.project"
                >
                  <template v-slot:no-data>
                    <v-list-item>
                      <v-list-item-content>
                        <v-list-item-title>No Project Found.</v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </template>
                  <template v-slot:item="{ item }">
                    <v-chip
                      small
                      outlined
                      label
                      color="chip-custom-blue"
                      class="font-size-16 font-weight-500"
                      >{{ item.barcode }}</v-chip
                    >
                    <p
                      class="ml-2 font-size-16 font-weight-500 text-truncate mb-0"
                      style="max-width: 250px"
                    >
                      {{ item.name }}
                    </p>
                  </template>
                  <template v-slot:selection="{ item }">
                    <v-chip
                      small
                      outlined
                      label
                      color="chip-custom-blue"
                      class="font-size-16 font-weight-500"
                      >{{ item.barcode }}</v-chip
                    >
                    <p
                      class="ml-2 font-size-16 font-weight-500 text-truncate mb-0"
                      style="max-width: 150px"
                    >
                      {{ item.name }}
                    </p>
                  </template>
                </v-autocomplete>
              </td>
            </tr>
            <tr v-if="false && !isPackage">
              <td
                width="40%"
                height="50px"
                class="font-weight-500 font-size-16"
              >
                Sales representative
              </td>
              <td
                width="60%"
                height="50px"
                class="font-weight-600 font-size-16"
              >
                <v-autocomplete
                  dense
                  color="cyan"
                  filled
                  label="Sales representatives"
                  solo
                  flat
                  item-color="cyan"
                  item-text="full_name"
                  item-value="id"
                  hide-details
                  :disabled="form_loading"
                  :loading="form_loading"
                  :items="sales_executive_list"
                  v-model="quotation.sales_person"
                  v-on:change="update_sales()"
                >
                  <template v-slot:no-data>
                    <v-list-item>
                      <v-list-item-content>
                        <v-list-item-title
                          >No Sales Representative Found.</v-list-item-title
                        >
                      </v-list-item-content>
                    </v-list-item>
                  </template>
                </v-autocomplete>
              </td>
            </tr>
            <tr>
              <td
                width="40%"
                height="50px"
                class="font-weight-500 font-size-16"
              >
                Prepared by
              </td>
              <td
                width="60%"
                height="50px"
                class="font-weight-600 font-size-16"
              >
                <v-autocomplete
                  dense
                  color="cyan"
                  filled
                  label="Prepared by"
                  solo
                  flat
                  item-color="cyan"
                  item-text="full_name"
                  item-value="id"
                  hide-details
                  :disabled="form_loading"
                  :loading="form_loading"
                  :items="sales_executive_list"
                  v-model="quotation.prepared_by_id"
                  v-on:change="update_prepared_by($event)"
                >
                  <template v-slot:no-data>
                    <v-list-item>
                      <v-list-item-content>
                        <v-list-item-title
                          >No Prepared by Found.</v-list-item-title
                        >
                      </v-list-item-content>
                    </v-list-item>
                  </template>
                </v-autocomplete>
              </td>
            </tr>
            <tr>
              <td
                width="40%"
                height="50px"
                class="font-weight-500 font-size-16"
              >
                CO-Designer
              </td>
              <td
                width="60%"
                height="50px"
                class="font-weight-600 font-size-16"
              >
                <v-autocomplete
                  dense
                  color="cyan"
                  filled
                  label="CO-Designer"
                  solo
                  flat
                  item-color="cyan"
                  item-text="full_name"
                  item-value="id"
                  hide-details
                  :disabled="form_loading"
                  :loading="form_loading"
                  :items="sales_executive_list"
                  v-model="quotation.assistant"
                >
                  <template v-slot:no-data>
                    <v-list-item>
                      <v-list-item-content>
                        <v-list-item-title
                          >No CO-Designer Found.</v-list-item-title
                        >
                      </v-list-item-content>
                    </v-list-item>
                  </template>
                </v-autocomplete>
              </td>
            </tr>
            <tr v-if="false">
              <td height="50px" class="font-weight-500 font-size-16">
                Email Notification
              </td>
              <td height="50px" class="font-weight-600 font-size-16">
                <v-layout>
                  <v-flex md6>
                    <v-checkbox
                      hide-details
                      class="my-0 py-0"
                      color="cyan"
                      label="Admin"
                      :true-value="1"
                      :false-value="0"
                      :disabled="form_loading"
                      v-model="quotation.admin_notify"
                    ></v-checkbox>
                  </v-flex>
                  <v-flex md6>
                    <v-checkbox
                      hide-details
                      class="my-0 py-0"
                      color="cyan"
                      label="Customer"
                      :true-value="1"
                      :false-value="0"
                      :disabled="form_loading"
                      v-model="quotation.customer_notify"
                    ></v-checkbox>
                  </v-flex>
                </v-layout>
              </td>
            </tr>
          </table>
        </v-flex>
      </v-layout>
      <v-layout class="mx-8 my-4">
        <v-flex md12>
          <v-card flat class="custom-grey-border remove-border-radius">
            <v-card-title class="headline grey lighten-4">
              <h3 class="font-weight-700 custom-headline color-custom-blue">
                Line Items
              </h3>
            </v-card-title>
            <v-card-text class="p-6 font-size-16 custom-line-item-template">
              <div>
                <v-btn
                  color="cyan"
                  small
                  outlined
                  class="custom-bold-button ml-2"
                  v-on:click="package_dialog = true"
                >
                  Package
                </v-btn>
              </div>
              <br />
              <line-item-package
                :item-instance="itemInstance"
                :uom-list="uomList"
                :is-package="isPackage"
                v-model="packages"
                item-title="Package"
                :disabled="form_loading"
                :update-mode="update_mode"
              ></line-item-package>
              <br />
              <line-item
                :item-instance="itemInstance"
                :uom-list="uomList"
                color="orange--text text--darken-4"
                v-if="!isPackage"
                :is-package="isPackage"
                v-model="line_items"
                item-title="Additional Works"
                :disabled="form_loading"
                :update-mode="update_mode"
              ></line-item>
              <v-row>
                <v-col md6>
                  <table width="100%" style="table-layout: fixed">
                    <tr>
                      <td height="50px" align="left" width="100%">
                        <v-btn
                          v-on:click="add_adtitle()"
                          small
                          depressed
                          color="cyan white--text"
                          class="custom-bold-button white--text"
                        >
                          <v-icon>mdi-plus</v-icon> Add Additional Work Title
                          (New Title)
                        </v-btn>
                      </td>
                    </tr>
                  </table>
                  <table width="100%" style="table-layout: fixed">
                    <tr>
                      <td height="50px" align="right" width="40%"></td>
                      <td height="50px" align="right" width="40%">
                        <label class="font-size-16 font-weight-500 m-0"
                          >Total Supplier Cost</label
                        >
                      </td>
                      <td height="50px" align="right" width="20%">
                        <v-tooltip top content-class="custom-top-tooltip">
                          <template v-slot:activator="{ on, attrs }">
                            <label
                              v-bind="attrs"
                              v-on="on"
                              class="font-size-16 font-weight-600 m-0"
                              >{{ formatMoney(ltxCalculation.acc_supplier) }}</label
                            >
                          </template>
                          <span>Sum of All Line Item Total Supplier Cost</span>
                        </v-tooltip>
                      </td>
                    </tr>
                    <tr>
                      <td height="50px" align="right" width="40%"></td>
                      <td height="50px" align="right" width="40%">
                        <label class="font-size-16 font-weight-500 m-0"
                          >Total Profit</label
                        >
                      </td>
                      <td height="50px" align="right" width="20%">
                        <v-tooltip top content-class="custom-top-tooltip">
                          <template v-slot:activator="{ on, attrs }">
                            <label
                              v-bind="attrs"
                              v-on="on"
                              class="font-size-16 font-weight-600 m-0"
                              >{{ formatMoney(ltxCalculation.acc_profit) }}</label
                            >
                          </template>
                          <span
                            >Sub Total - (Total Supplier Cost + Discount Cost)</span
                          >
                        </v-tooltip>
                      </td>
                    </tr>
                    <tr>
                      <td height="50px" align="right" width="40%"></td>
                      <td height="50px" align="right" width="40%">
                        <label class="font-size-16 font-weight-500 m-0"
                          >Total Quotation Mark Up (in %)</label
                        >
                      </td>
                      <td height="50px" align="right" width="20%">
                        <v-tooltip top content-class="custom-top-tooltip">
                          <template v-slot:activator="{ on, attrs }">
                            <label
                              v-bind="attrs"
                              v-on="on"
                              class="font-size-16 font-weight-600 m-0"
                              >{{ formatNumber(ltxCalculation.acc_markup_percent) }}%</label
                            >
                          </template>
                          <span>((Sub Total - Discount Cost) / Total Supplier Cost) - 1</span>
                        </v-tooltip>
                      </td>
                    </tr>
                    <tr>
                      <td height="50px" align="right" width="40%"></td>
                      <td height="50px" align="right" width="40%">
                        <label class="font-size-16 font-weight-500 m-0"
                          >Total Quotation Profit (in %)</label
                        >
                      </td>
                      <td height="50px" align="right" width="20%">
                        <v-tooltip top content-class="custom-top-tooltip">
                          <template v-slot:activator="{ on, attrs }">
                            <label
                              v-bind="attrs"
                              v-on="on"
                              class="font-size-16 font-weight-600 m-0"
                              >{{ formatNumber(ltxCalculation.acc_profit_percent) }}%</label
                            >
                          </template>
                          <span>Total Profit / Sub Total</span>
                        </v-tooltip>
                      </td>
                    </tr>
                  </table>
                </v-col>
                <v-col md6>
                  <table width="100%" style="table-layout: fixed">
                    <tr>
                      <td height="50px" align="right" width="40%">
                        <label class="font-size-16 font-weight-500 m-0"
                          >Sub Total</label
                        >
                      </td>
                      <td height="50px" align="center" width="35%"></td>
                      <td height="50px" align="right" width="25%">
                        <label class="font-size-16 font-weight-600 m-0">{{
                          formatMoney(ltxCalculation.sub_total)
                        }}</label>
                      </td>
                    </tr>
                    <tr v-if="!isPackage">
                      <td height="50px" align="right" width="40%">
                        <label class="font-size-16 font-weight-500 m-0"
                          >Optional Total</label
                        >
                      </td>
                      <td height="50px" align="center" width="35%"></td>
                      <td height="50px" align="right" width="25%">
                        <label class="font-size-16 font-weight-600 m-0">{{
                          formatMoney(ltxCalculation.optional_total)
                        }}</label>
                      </td>
                    </tr>
                    <tr class="qt-discount">
                      <td height="50px" align="right" width="40%">
                        <label class="font-size-16 font-weight-500 m-0"
                          >Discount
                        </label>
                      </td>
                      <td height="50px" align="center" width="35%">
                        <v-layout class="ml-4">
                          <v-flex md6>
                            <v-text-field
                              dense
                              filled
                              color="cyan"
                              label="Value"
                              solo
                              flat
                              v-model="ltxCalculation.discount_value"
                              type="number"
                              v-on:keypress="is_number($event)"
                              hide-details
                              clearable
                              :disabled="
                                form_loading || ltxCalculation.sub_total < 1
                              "
                              :loading="form_loading"
                            />
                          </v-flex>
                          <v-flex md6>
                            <v-select
                              :items="discount_type_list"
                              dense
                              filled
                              flat
                              hide-details
                              solo
                              item-text="type"
                              item-value="value"
                              color="cyan"
                              item-color="cyan"
                              v-model="ltxCalculation.discount_type"
                              :disabled="
                                form_loading || ltxCalculation.sub_total < 1
                              "
                              class="discount-append-outer"
                              :loading="form_loading"
                            ></v-select>
                          </v-flex>
                        </v-layout>
                      </td>
                      <td height="50px" align="right" width="25%">
                        <label class="font-size-16 font-weight-600 m-0">{{
                          formatMoney(ltxCalculation.discount_amount)
                        }}</label>
                      </td>
                    </tr>
                    <tr class="qt-discount">
                      <td height="50px" align="right" width="40%">
                        <label class="font-size-16 font-weight-500 m-0"
                          >Discount
                        </label>
                      </td>
                      <td height="50px" align="center" width="35%">
                        <v-layout class="ml-4">
                          <v-flex md6>
                            <v-text-field
                              dense
                              filled
                              color="cyan"
                              label="Value"
                              solo
                              flat
                              v-model="ltxCalculation.discount_two_value"
                              type="number"
                              v-on:keypress="is_number($event)"
                              hide-details
                              clearable
                              :disabled="form_loading || ltxCalculation.sub_total < 1"
                              :loading="form_loading"
                            />
                          </v-flex>
                          <v-flex md6>
                            <v-select
                              :items="discount_type_list"
                              dense
                              filled
                              flat
                              hide-details
                              solo
                              item-text="type"
                              item-value="value"
                              color="cyan"
                              item-color="cyan"
                              v-model="ltxCalculation.discount_two_type"
                              :disabled="form_loading || ltxCalculation.sub_total < 1"
                              class="discount-append-outer"
                              :loading="form_loading"
                            ></v-select>
                          </v-flex>
                        </v-layout>
                      </td>
                      <td height="50px" align="right" width="25%">
                        <label class="font-size-16 font-weight-600 m-0">{{ formatMoney(ltxCalculation.discount_two_amount) }}</label>
                      </td>
                    </tr>
                    <tr>
                      <td height="50px" align="right" width="40%">
                        <label class="font-size-16 font-weight-500 m-0"
                          >Tax [{{ formatNumber(ltxCalculation.tax_value) }}%]
                        </label>
                      </td>
                      <td height="50px" align="center" width="35%">
                        <v-layout class="align-center">
                          <v-flex md3 class="text-left">
                            <v-checkbox
                              readonly
                              hide-details
                              class="ml-4 my-0 py-0"
                              color="cyan"
                              :true-value="1"
                              :false-value="0"
                              v-model="ltxCalculation.tax_applied"
                              :disabled="
                                form_loading || ltxCalculation.sub_total < 1
                              "
                            />
                          </v-flex>
                          <v-flex md9 class="text-left">
                            <template v-if="ltxCalculation.tax_applied">
                              <v-tooltip top content-class="custom-top-tooltip">
                                <template v-slot:activator="{ on, attrs }">
                                  <label
                                    v-bind="attrs"
                                    v-on="on"
                                    class="cursor-pointer font-size-16 font-weight-600 m-0"
                                    >{{
                                      formatMoney(ltxCalculation.taxable_amount)
                                    }}</label
                                  >
                                </template>
                                <span class="text-capitalize"
                                  >Taxable Amount</span
                                >
                              </v-tooltip>
                            </template>
                          </v-flex>
                        </v-layout>
                      </td>
                      <td height="50px" align="right" width="25%">
                        <label class="font-size-16 font-weight-600 m-0">{{
                          formatMoney(ltxCalculation.tax_amount)
                        }}</label>
                      </td>
                    </tr>
                    <tr>
                      <td height="50px" align="right" width="40%">
                        <label class="font-size-16 font-weight-500 m-0"
                          >Adjustment</label
                        >
                      </td>
                      <td height="50px" align="center" width="35%">
                        <v-text-field
                          dense
                          filled
                          color="cyan"
                          label="Adjustment"
                          solo
                          flat
                          class="ml-4"
                          v-model="ltxCalculation.adjustment"
                          type="number"
                          v-on:keypress="is_number($event)"
                          hide-details
                          clearable
                          :disabled="
                            form_loading || ltxCalculation.sub_total < 1
                          "
                          :loading="form_loading"
                        />
                      </td>
                      <td height="50px" align="right" width="25%">
                        <label class="font-size-16 font-weight-600 m-0">{{
                          formatMoney(ltxCalculation.adjustment)
                        }}</label>
                      </td>
                    </tr>
                    <tr>
                      <td height="50px" align="right" width="40%">
                        <label class="font-size-16 font-weight-500 m-0"
                          >Grand Total
                        </label>
                      </td>
                      <td height="50px" align="center" width="35%"></td>
                      <td height="50px" align="right" width="25%">
                        <label class="font-size-16 font-weight-600 m-0">{{
                          formatMoney(ltxCalculation.total)
                        }}</label>
                      </td>
                    </tr>
                  </table>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-flex>
      </v-layout>
      <v-layout v-if="false" class="mx-8 my-4">
        <v-flex md12>
          <v-card flat class="custom-grey-border remove-border-radius">
            <v-card-title class="headline grey lighten-4">
              <h3 class="font-weight-700 custom-headline color-custom-blue">
                Terms &amp; Conditions
              </h3>
            </v-card-title>
            <v-card-text class="p-6 font-size-16">
              <tiny-mce
                :disabled="form_loading"
                v-model="quotation.term_conditions"
              />
            </v-card-text>
          </v-card>
        </v-flex>
      </v-layout>
      <v-layout class="mx-8 my-4">
        <v-flex md12>
          <v-card flat class="custom-grey-border remove-border-radius">
            <v-card-title class="headline grey lighten-4">
              <h3 class="font-weight-700 custom-headline color-custom-blue">
                Internal Notes &amp; Attachments
              </h3>
            </v-card-title>
            <v-card-text class="p-6 font-size-16">
              <v-layout>
                <v-flex class="mr-4" md6>
                  <label class="font-size-16 font-weight-600"
                    >Internal Note</label
                  >
                  <v-autocomplete
                    dense
                    color="cyan"
                    filled
                    label="Internal Note"
                    solo
                    flat
                    class="my-2"
                    item-color="cyan"
                    item-text="label"
                    item-value="id"
                    hide-details
                    :disabled="form_loading"
                    :loading="form_loading"
                    :items="adminNoteList"
                    v-model="quotation.admin_note_id"
                    v-on:change="update_admin_note()"
                  >
                  </v-autocomplete>
                  <tiny-mce
                    :disabled="form_loading"
                    v-model="quotation.admin_note"
                  />
                </v-flex>
                <v-flex md6>
                  <label class="font-size-16 font-weight-600"
                    >Client Note
                    <span class="text--secondary font-weight-500"
                      >(visible on PDF)</span
                    ></label
                  >
                  <v-autocomplete
                    dense
                    color="cyan"
                    filled
                    label="Client Note"
                    solo
                    flat
                    class="my-2"
                    item-color="cyan"
                    item-text="label"
                    item-value="id"
                    hide-details
                    :disabled="form_loading"
                    :loading="form_loading"
                    :items="clientNoteList"
                    v-model="quotation.client_note_id"
                    v-on:change="update_client_note()"
                  >
                  </v-autocomplete>
                  <tiny-mce
                    :disabled="form_loading"
                    v-model="quotation.client_note"
                  />
                </v-flex>
              </v-layout>
              <v-layout class="mt-4">
                <v-flex md6 class="mr-4">
                  <label class="font-size-16 font-weight-600"
                    >Attachments</label
                  >
                  <table width="100%">
                    <tbody>
                      <tr
                        v-for="(row, index) in quotation.attachments"
                        :key="index"
                      >
                        <td width="50%" class="pr-2 py-2">
                          <v-file-input
                            prepend-inner-icon="mdi-attachment mdi-rotate-90"
                            placeholder="Click here to select file"
                            label="Click here to select file"
                            prepend-icon=""
                            color="cyan"
                            solo
                            flat
                            v-model="row.accepted_file"
                            dense
                            v-on:change="update_file_name(index)"
                            hide-details
                          ></v-file-input>
                        </td>
                        <td width="50%" class="pl-2 py-2">
                          <v-text-field
                            placeholder="File Name"
                            label="File Name"
                            dense
                            solo
                            flat
                            color="cyan"
                            v-model="row.file_name"
                            hide-details
                            class="has-delete-outer-icon"
                            append-outer-icon="mdi-delete"
                            v-on:click:append-outer="remove_row(index)"
                            :suffix="get_file_extension(row.file_type)"
                          ></v-text-field>
                        </td>
                      </tr>
                      <tr>
                        <td height="10px" colspan="2"></td>
                      </tr>
                      <tr v-if="quotation.attachments.length < 5">
                        <td colspan="2">
                          <v-btn
                            color="cyan white--text"
                            small
                            v-on:click="add_attachment_row()"
                            class="custom-bold-button"
                            >Add More...</v-btn
                          >
                        </td>
                      </tr>
                      <tr>
                        <td height="10px" colspan="2"></td>
                      </tr>
                    </tbody>
                  </table>
                </v-flex>
                <v-flex md6 class="ml-4"> </v-flex>
              </v-layout>
            </v-card-text>
          </v-card>
        </v-flex>
      </v-layout>
      <v-layout class="mx-8 mt-8 mb-4">
        <v-flex class="text-right">
          <v-btn
            v-on:click="goBack()"
            class="mx-2 custom-grey-border custom-bold-button"
            depressed
            :disabled="form_loading"
          >
            Cancel
          </v-btn>
          <v-btn
            class="ml-2 custom-bold-button white--text"
            color="cyan"
            :loading="form_loading"
            :disabled="form_loading || !form_status"
            v-on:click="update_or_create('quote')"
          >
            <template v-if="update_mode">Update</template>
            <template v-else>Save</template> {{ get_title(true) }}
          </v-btn>
        </v-flex>
      </v-layout>
    </v-form>
    <line-item-package-search-dialog
      :is-package="isPackage"
      :update-mode="update_mode"
      :value="ltxPackage"
      item-title="Package"
      item-type="package"
      :item-dialog="package_dialog"
      v-on:close="package_dialog = false"
    ></line-item-package-search-dialog>
    <line-item-search-dialog
      :is-package="isPackage"
      :update-mode="update_mode"
      v-model="line_items"
      item-title="Additional Work"
      item-type="service"
      :item-dialog="service_dialog"
      v-on:close="service_dialog = false"
    ></line-item-search-dialog>
    <prevent-dialog :common-dialog="route_prevent_dialog" :dialogWidth="600">
      <template v-slot:body>
        <v-layout>
          <v-flex md2 class="text-center">
            <!--begin::Svg Icon-->
            <inline-svg
              width="50"
              :src="$assetURL('media/custom-svg/attention-circle.svg')"
            />
            <!--end::Svg Icon-->
          </v-flex>
          <v-flex md10>
            <p class="font-weight-700">
              Are you sure you want to leave this page?
            </p>
            <v-layout>
              <v-flex>
                <v-btn
                  class="mr-2 custom-grey-border custom-bold-button white--text"
                  color="cyan"
                  small
                  v-on:click="stay_on_page()"
                >
                  Stay on this page
                </v-btn>
                <v-btn
                  class="mx-2 custom-grey-border custom-bold-button"
                  small
                  v-on:click="leave_page()"
                >
                  Leave this page
                </v-btn>
              </v-flex>
            </v-layout>
          </v-flex>
        </v-layout>
      </template>
    </prevent-dialog>

    <div v-if="globalLoading || form_loading" class="pre-quote-global-loader">      
      <div class="text-center">
        <img alt="brang-logo" :src="loaderLogo" width="100" />
        <br>
        <br>
        <div>
          <span class="text-black font-weight-500 font-size-h1">Please wait...</span>
        </div>
      </div>
    </div>
  </v-container>
</template>

<style type="text/css">
  .pre-quote-global-loader {    
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgb(255 255 255 / 68%); /* Adjust opacity or color as needed */
    z-index: 99; /* Ensure it's on top of other content */
    display: flex;
    justify-content: center;
    align-items: flex-start;
    padding-top: 200px;
  }
</style>

<script>
import LineItemPackageSearchDialog from "@/view/pages/partials/Line-Item-Package-Search-Dialog";
import LineItemSearchDialog from "@/view/pages/partials/Line-Item-Search-Dialog";
import PreventDialog from "@/view/pages/partials/Dialog";
import { ErrorEventBus, InitializeError } from "@/core/lib/message.lib";
import LineItemPackage from "@/view/pages/partials/Line-Item-Package";
import {
  QUERY,
  POST,
  PUT,
  GET,
  UPLOAD,
} from "@/core/services/store/request.module";
import {
  ADD_ITEM,
  CLEAR_LINE_ITEM,
  SET_PACKAGE,
  SET_LINE_ITEM,
  SET_CHILD_ITEM,
  SET_LEVEL2_CHILD_ITEM,
  GET_CHILD_LIST,
  SET_UOM_LIST,
  SET_DISCOUNT,
} from "@/core/services/store/line.item.module";
import { PreventDialogEventBus } from "@/core/lib/prevent.reload.lib";
import DatePicker from "@/view/pages/partials/Datepicker";
import ValidationMixin from "@/core/plugins/validation-mixin";
import LineItem from "@/view/pages/partials/Line-Item-NewV1";
import TinyMCE from "@/view/pages/partials/TinyMCE.vue";
import CommonMixin from "@/core/plugins/common-mixin";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import ManageLineItem from "@/core/plugins/line.item.plugin";
import ObjectPath from "object-path";
import moment from "moment-timezone";
import { mapGetters } from "vuex";

moment.tz.setDefault(process.env.VUE_APP_TIMEZONE);

export default {
  name: "create-quotation",
  mixins: [CommonMixin, ValidationMixin],
  props: {
    isQuotation: {
      type: Boolean,
      default: false,
    },
    isPackage: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    line_items: {
      deep: true,
      handler(param) {
        console.log(param);
      },
    },
  },
  data() {
    return {
      uomList: [],
      adminNoteList: [],
      clientNoteList: [],
      packages: [],
      line_items: [],
      itemInstance: {},
      back_force: false,
      form_status: true,
      form_loading: false,
      service_dialog: false,
      package_dialog: false,
      case_trust_dialog: false,
      route_prevent_dialog: false,
      update_mode: false,
      discount_type_list: [
        { type: "%", value: 2 },
        { type: "$", value: 1 },
      ],
      today_date: null,
      barcode: null,
      customer: {},
      property: {},
      billing: {},
      contact_person: {},
      new_contact_person: {},
      prospect: {},
      project_list: [],
      sales_executive_list: [],
      quotation_id: 0,
      is_duplicate: null,
      duplicate_id: 0,
      package_id: 0,
      prospect_id: 0,
      quotation: {
        action: "quote",
        customer: 0,
        barcode: null,
        property: 0,
        billing: 0,
        project: 0,
        variation: 0,
        assistant: 0,
        variation_quotation: 0,
        is_variation: 0,
        date: new Date().toISOString().substr(0, 10),
        contact_person: 0,
        new_contact_person: 0,
        admin_note_id: 0,
        client_note_id: 0,
        title: null,
        description: null,
        rating: 1,
        reference: null,
        sales_person: 0,
        sales: null,
        prepared_by: null,
        prepared_by_id: null,
        admin_notify: 0,
        customer_notify: 0,
        discount_value: null,
        discount_type: 1,
        is_taxed: 1,
        adjustment: null,
        term_conditions: null,
        client_note: null,
        admin_note: null,
        case_trust: {
          agreement_date: null,
          first_party: {
            name: null,
            contact_no: null,
            nric_no: null,
          },
          second_party: {
            name: null,
            contact_no: null,
            nric_no: null,
          },
          address: null,
          estimated_date: null,
          package_sum: null,
          additional_work_sum: null,
          standalone_contract_sum: null,
          package: [
            {
              col_one: null,
              col_two: "% payable at various stages",
              col_three: "Amount payable at various stages",
            },
            {
              col_one: "Immediately upon Signing this Agreement",
              col_two: "10% of package or $1,200 (whichever is higher)",
              col_three: null,
            },
            {
              col_one: "Commencement of Work",
              col_two: "10% of package or $1,200 (whichever is higher)",
              col_three: null,
            },
            {
              col_one: "Carpentry Fabrication",
              col_two: "45% of package",
              col_three: null,
            },
            {
              col_one: "Handover Date",
              col_two: "5% of package",
              col_three: "Amount payable at various stages",
            },
            {
              col_one: "Additional Works",
              col_two: "100% of additional works",
              col_three: "To show detailed breakdown at full quotation",
            },
          ],
          contract: [
            {
              col_one: null,
              col_two: "% payable at various stages",
              col_three: "Amount payable at various stages",
            },
            {
              col_one: "Immediately upon Signing this Agreement",
              col_two: "10% of contract",
              col_three: null,
            },
            {
              col_one: "Commencement of Work",
              col_two: "40% of contract",
              col_three: null,
            },
            {
              col_one: "Carpentry Fabrication",
              col_two: "45% of contract",
              col_three: null,
            },
            {
              col_one: "Handover Date",
              col_two: "5% of contract",
              col_three: null,
            },
          ],
        },
        attachments: [
          {
            accepted_file: null,
            file_name: null,
            file_type: null,
          },
        ],
      },
      total_supplier_cost: 0,
      total_profit: 0,
      sub_total: 0,
      quotation_attachments: [],
    };
  },
  components: {
    "tiny-mce": TinyMCE,
    "line-item": LineItem,
    "date-picker": DatePicker,
    "prevent-dialog": PreventDialog,
    "line-item-package": LineItemPackage,
    "line-item-search-dialog": LineItemSearchDialog,
    "line-item-package-search-dialog": LineItemPackageSearchDialog,
  },
  methods: {
    update_prepared_by(id) {
      this.quotation.prepared_by = null;
      this.quotation.prepared_by_id = 0;
      if(Array.isArray(this.sales_executive_list)){
        const sales_executive = this.sales_executive_list.find((row) => row.id == id);
        if (sales_executive?.id) {
          this.quotation.prepared_by = sales_executive?.full_name ?? null;
          this.quotation.prepared_by_id = this.lodash.toSafeInteger((sales_executive?.id ?? 0));
        }
      }
    },
    add_adtitle() {
      this.$store.dispatch(ADD_ITEM);
    },
    update_admin_note() {
      let output = this.lodash.find(this.adminNoteList, {
        id: this.quotation.admin_note_id,
      });
      if (output) {
        this.quotation.admin_note = output.description;
      }
    },
    update_client_note() {
      let output = this.lodash.find(this.clientNoteList, {
        id: this.quotation.client_note_id,
      });
      if (output) {
        this.quotation.client_note = output.description;
      }
    },
    get_title(short) {
      if (this.isPackage) {
        return "Package";
      } else {
        if (short) {
          return "Quote";
        }
        return "Quotation";
      }
    },
    update_sales() {
      const sales_executive = this.lodash.find(this.sales_executive_list, {
        id: this.quotation.sales_person,
      });
      if (sales_executive) {
        this.quotation.sales = sales_executive.full_name;
      }
    },
    remove_row(index) {
      ObjectPath.del(this.quotation.attachments, index);
      this.$nextTick(() => {
        if (!this.quotation.attachments.length) {
          this.add_attachment_row();
        }
      });
    },
    add_attachment_row() {
      if (this.quotation.attachments.length < 5) {
        this.quotation.attachments.push({
          accepted_file: null,
          file_name: null,
          file_type: null,
        });
      }
    },
    get_file_extension(file_type) {
      if (file_type) {
        return `.${file_type}`;
      }
      return null;
    },
    update_file_name(index) {
      const file = this.quotation.attachments[index].accepted_file;
      if (file) {
        this.quotation.attachments[index].file_name = file.name
          .split(".")
          .slice(0, -1)
          .join(".");
        this.quotation.attachments[index].file_type = file.name
          .split(".")
          .pop();
        this.add_attachment_row();
      }
    },
    get_attributes() {
      if (!this.prospect_id) {
        return false;
      }

      this.$store
        .dispatch(QUERY, {
          url: "pre-quotation/options",
          data: { prospect: this.prospect_id },
        })
        .then((response) => {
          this.barcode = ObjectPath.get(response, "data.barcode");
          this.$store.commit(
            SET_UOM_LIST,
            ObjectPath.get(response, "data.uomlist", [])
          );
          this.adminNoteList = ObjectPath.get(
            response,
            "data.admin_notes_list"
          );
          this.clientNoteList = ObjectPath.get(
            response,
            "data.client_notes_list"
          );
          this.sales_executive_list = ObjectPath.get(
            response,
            "data.sales_user_list"
          );

          if (!this.update_mode) {
            let defaultClientObject = this.lodash.find(this.clientNoteList, {
              primary: 1,
            });
            if (defaultClientObject) {
              this.quotation.client_note_id = defaultClientObject.id;
              this.quotation.client_note = defaultClientObject.description;
            }

            let defaultAdminObject = this.lodash.find(this.adminNoteList, {
              primary: 1,
            });
            if (defaultAdminObject) {
              this.quotation.admin_note_id = defaultAdminObject.id;
              this.quotation.admin_note = defaultAdminObject.description;
            }
          }

          this.prospect = ObjectPath.get(response, "data.prospect", {});

          if (!this.prospect) {
            this.back_force = true;
            this.goBack();
            return false;
          }

          /*this.quotation.admin_note = ObjectPath.get(
            response,
            "data.option.admin_note"
          );
          this.quotation.client_note = ObjectPath.get(
            response,
            "data.option.client_note"
          );*/
          this.quotation.term_conditions = ObjectPath.get(
            response,
            "data.option.terms_conditions"
          );

          this.quotation.prepared_by_id = ObjectPath.get(response, "data.user_id", 0);
          this.update_prepared_by(this.quotation.prepared_by_id);
        })
        .catch((error) => {
          this.logError(error);
          this.back_force = true;
          this.goBack();
        })
        .finally(() => {
          // this.pageLoading = false;
        });
    },
    stay_on_page() {
      PreventDialogEventBus.$emit("stay-on-page", true);
    },
    leave_page() {
      PreventDialogEventBus.$emit("leave-page", true);
    },
    get_quotation(quotation_id) {
      this.$store
        .dispatch(GET, { url: `pre-quotation/${quotation_id}?is-edit=1` })
        .then((response) => {
          this.quotation.barcode = ObjectPath.get(
            response,
            "data.quotation.barcode",
            null
          );
          this.quotation.customer = ObjectPath.get(
            response,
            "data.quotation.customer",
            0
          );
          this.quotation.variation = ObjectPath.get(
            response,
            "data.quotation.variation",
            0
          );
          this.quotation.variation_quotation = ObjectPath.get(
            response,
            "data.quotation.variation_quotation",
            0
          );
          this.quotation.property = ObjectPath.get(
            response,
            "data.quotation.property",
            0
          );
          this.quotation.admin_note_id = ObjectPath.get(
            response,
            "data.quotation.admin_note_id",
            0
          );
          this.quotation.client_note_id = ObjectPath.get(
            response,
            "data.quotation.client_note_id",
            0
          );
          this.quotation.billing = ObjectPath.get(
            response,
            "data.quotation.billing",
            0
          );
          this.quotation.project = ObjectPath.get(
            response,
            "data.quotation.project",
            0
          );
          this.quotation.prospect = ObjectPath.get(
            response,
            "data.quotation.prospect",
            0
          );
          this.quotation.date = ObjectPath.get(
            response,
            "data.quotation.date",
            null
          );
          this.quotation.contact_person = ObjectPath.get(
            response,
            "data.quotation.contact_person",
            0
          );
          this.quotation.new_contact_person = ObjectPath.get(
            response,
            "data.quotation.new_contact_person",
            0
          );
          this.quotation.title = ObjectPath.get(
            response,
            "data.quotation.quotation_title",
            null
          );
          this.quotation.description = ObjectPath.get(
            response,
            "data.quotation.description",
            null
          );
          this.quotation.rating = ObjectPath.get(
            response,
            "data.quotation.rating",
            1
          );
          this.quotation.reference = ObjectPath.get(
            response,
            "data.quotation.reference",
            null
          );
          this.quotation.sales_person = ObjectPath.get(
            response,
            "data.quotation.sales_person",
            null
          );
          this.quotation.sales = ObjectPath.get(
            response,
            "data.quotation.sales",
            null
          );
          this.quotation.prepared_by_id = ObjectPath.get(response, "data.quotation.prepared_by_id", 0);
          this.update_prepared_by(this.quotation.prepared_by_id);
          this.quotation.assistant = ObjectPath.get(
            response,
            "data.quotation.assistant",
            null
          );
          this.quotation.admin_notify = ObjectPath.get(
            response,
            "data.quotation.notify_admin",
            0
          );
          this.quotation.customer_notify = ObjectPath.get(
            response,
            "data.quotation.notify_customer",
            0
          );
          this.quotation.discount_value = ObjectPath.get(
            response,
            "data.quotation.discount_value",
            0
          );
          this.quotation.discount_type = ObjectPath.get(
            response,
            "data.quotation.discount_type",
            1
          );
          this.quotation.is_taxed = ObjectPath.get(
            response,
            "data.quotation.is_taxed",
            0
          );
          this.quotation.adjustment = ObjectPath.get(
            response,
            "data.quotation.adjustment",
            0
          );
          this.quotation.term_conditions = ObjectPath.get(
            response,
            "data.quotation.term_conditions",
            null
          );
          this.quotation.client_note = ObjectPath.get(
            response,
            "data.quotation.client_remark",
            null
          );
          this.quotation.admin_note = ObjectPath.get(
            response,
            "data.quotation.admin_remark",
            null
          );
          this.quotation.is_variation = ObjectPath.get(
            response,
            "data.quotation.is_variation",
            0
          );
          const packages = ObjectPath.get(response, "data.packages", []);
          const line_items = ObjectPath.get(response, "data.edit_services", {});

          this.$store.commit(SET_PACKAGE, packages);
          this.$store.commit(SET_LINE_ITEM, line_items.level_one);
          this.$store.commit(SET_CHILD_ITEM, line_items.level_two);
          this.$store.commit(SET_LEVEL2_CHILD_ITEM, line_items.level_three);

          this.$store.dispatch(GET_CHILD_LIST);

          this.$store.dispatch(SET_DISCOUNT, {
            discount_type: ObjectPath.get(response, "data.quotation.discount_value_type", 1),
            discount_value: ObjectPath.get(response, "data.quotation.discount_value", 0),
            discount_amount: ObjectPath.get(response, "data.quotation.discount_amount", 0),
            discount_two_value: ObjectPath.get(response, "data.quotation.discount_two_value", 0),
            discount_two_type: ObjectPath.get(response, "data.quotation.discount_two_type", 0),
            discount_two_amount: ObjectPath.get(response, "data.quotation.discount_two_amount", 0),
            adjustment: ObjectPath.get(response, "data.quotation.adjustment", 0),
            tax_applied: ObjectPath.get(response, "data.quotation.is_taxed", 0),
            tax_value: ObjectPath.get(response, "data.quotation.tax_value", 0),
          });

          const page_title = this.get_title();

          this.$store.dispatch(SET_BREADCRUMB, [
            { title: page_title },
            { title: "Update" },
            { title: this.quotation.barcode },
          ]);
        })
        .catch((error) => {
          this.logError(error);
          this.back_force = true;
          this.goBack();
        })
        .finally(() => {
          this.form_loading = false;
        });
    },
    get_duplicate(quotation_id) {
      this.$store
        .dispatch(GET, {
          url: `pre-quotation/${quotation_id}?is-duplicate=1&is-edit=1`,
        })
        .then((response) => {
          this.quotation.customer = ObjectPath.get(
            response,
            "data.quotation.customer",
            0
          );
          this.quotation.variation = ObjectPath.get(
            response,
            "data.quotation.variation",
            0
          );
          this.quotation.variation_quotation = ObjectPath.get(
            response,
            "data.quotation.variation_quotation",
            0
          );
          this.quotation.property = ObjectPath.get(
            response,
            "data.quotation.property",
            0
          );
          this.quotation.admin_note_id = ObjectPath.get(
            response,
            "data.quotation.admin_note_id",
            0
          );
          this.quotation.client_note_id = ObjectPath.get(
            response,
            "data.quotation.client_note_id",
            0
          );
          this.quotation.billing = ObjectPath.get(
            response,
            "data.quotation.billing",
            0
          );
          this.quotation.project = ObjectPath.get(
            response,
            "data.quotation.project",
            0
          );
          this.quotation.prospect = ObjectPath.get(
            response,
            "data.quotation.prospect",
            0
          );
          this.quotation.date = this.today_date;
          this.quotation.contact_person = ObjectPath.get(
            response,
            "data.quotation.contact_person",
            0
          );
          this.quotation.new_contact_person = ObjectPath.get(
            response,
            "data.quotation.new_contact_person",
            0
          );
          this.quotation.title = ObjectPath.get(
            response,
            "data.quotation.quotation_title",
            null
          );
          this.quotation.description = ObjectPath.get(
            response,
            "data.quotation.description",
            null
          );
          this.quotation.rating = ObjectPath.get(
            response,
            "data.quotation.rating",
            1
          );
          this.quotation.reference = ObjectPath.get(
            response,
            "data.quotation.reference",
            null
          );
          this.quotation.sales_person = ObjectPath.get(
            response,
            "data.quotation.sales_person",
            null
          );
          this.quotation.sales = ObjectPath.get(
            response,
            "data.quotation.sales",
            null
          );

          this.quotation.prepared_by_id = ObjectPath.get(response, "data.quotation.prepared_by_id", 0);
          this.update_prepared_by(this.quotation.prepared_by_id);
          this.quotation.assistant = ObjectPath.get(
            response,
            "data.quotation.assistant",
            null
          );
          this.quotation.admin_notify = ObjectPath.get(
            response,
            "data.quotation.notify_admin",
            0
          );
          this.quotation.customer_notify = ObjectPath.get(
            response,
            "data.quotation.notify_customer",
            0
          );
          this.quotation.discount_value = ObjectPath.get(
            response,
            "data.quotation.discount_value",
            0
          );
          this.quotation.discount_type = ObjectPath.get(
            response,
            "data.quotation.discount_type",
            1
          );
          this.quotation.is_taxed = ObjectPath.get(
            response,
            "data.quotation.is_taxed",
            0
          );
          this.quotation.adjustment = ObjectPath.get(
            response,
            "data.quotation.adjustment",
            0
          );
          this.quotation.term_conditions = ObjectPath.get(
            response,
            "data.quotation.term_conditions",
            null
          );
          this.quotation.client_note = ObjectPath.get(
            response,
            "data.quotation.client_remark",
            null
          );
          this.quotation.admin_note = ObjectPath.get(
            response,
            "data.quotation.admin_remark",
            null
          );
          this.quotation.is_variation = ObjectPath.get(
            response,
            "data.quotation.is_variation",
            0
          );

          const packages = ObjectPath.get(response, "data.packages", []);
          const line_items = ObjectPath.get(response, "data.edit_services", {});

          this.$store.commit(SET_PACKAGE, packages);
          this.$store.commit(SET_LINE_ITEM, line_items.level_one);
          this.$store.commit(SET_CHILD_ITEM, line_items.level_two);
          this.$store.commit(SET_LEVEL2_CHILD_ITEM, line_items.level_three);

          this.$store.dispatch(GET_CHILD_LIST);

          this.$store.dispatch(SET_DISCOUNT, {
            discount_type: ObjectPath.get(response, "data.quotation.discount_value_type", 1),
            discount_value: ObjectPath.get(response, "data.quotation.discount_value", 0),
            discount_amount: ObjectPath.get(response, "data.quotation.discount_amount", 0),
            discount_two_value: ObjectPath.get(response, "data.quotation.discount_two_value", 0),
            discount_two_type: ObjectPath.get(response, "data.quotation.discount_two_type", 0),
            discount_two_amount: ObjectPath.get(response, "data.quotation.discount_two_amount", 0),
            adjustment: ObjectPath.get(response, "data.quotation.adjustment", 0),
            tax_applied: ObjectPath.get(response, "data.quotation.is_taxed", 0),
            tax_value: ObjectPath.get(response, "data.quotation.tax_value", 0),
          });
        })
        .catch((error) => {
          this.logError(error);
          this.back_force = true;
          this.goBack();
        })
        .finally(() => {
          this.form_loading = false;
        });
    },
    upload_files(form_data) {
      return new Promise((resolve, reject) => {
        this.$store
          .dispatch(UPLOAD, { url: "file-manager/upload", data: form_data })
          .then(({ data }) => {
            const output = this.lodash.map(data, "id");
            resolve(output);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    async update_or_create(action) {
      const validateStatus = this.$refs.quotation_form.validate();

      const formErrors = this.validateForm(this.$refs.quotation_form);
      if (formErrors.length) {
        for (let i = 0; i < formErrors.length; i++) {
          ErrorEventBus.$emit("update:error", InitializeError(formErrors[i]));
        }
        return false;
      }

      if (!validateStatus) {
        return false;
      }

      if (this.ltxPackage.length < 1 && this.ltxLineItem.length < 1) {
        ErrorEventBus.$emit("update:error", InitializeError("Atleast one line item is required."));
        return false;
      }

      this.form_loading = true;

      const awaitFunc = () => {
        return new Promise((resolve) => {
          setTimeout(resolve, 600);
        });
      };

      await awaitFunc();

      this.quotation_attachments = [];

      let attachments = ObjectPath.get(this.quotation, "attachments");

      if (attachments.length) {
        let do_update = false;
        let form_data = new FormData();
        for (let i = 0; i < attachments.length; i++) {
          let accepted_file = ObjectPath.get(attachments[i], "accepted_file");
          if (accepted_file instanceof File) {
            if (!do_update) {
              do_update = true;
            }
            form_data.append(`files[${i}]`, accepted_file, accepted_file.name);
          }
        }

        if (do_update) {
          this.quotation_attachments = await this.upload_files(form_data);
        }
      }

      const request = {
        action: action ? action : this.quotation.action,
        customer: this.quotation.customer,
        variation: this.quotation.variation,
        variation_quotation: this.quotation.variation_quotation,
        property: this.quotation.property,
        assistant: this.quotation.assistant,
        billing: this.quotation.billing,
        project: this.quotation.project,
        prospect: this.prospect_id,
        date: this.quotation.date,
        contact_person: this.quotation.contact_person,
        new_contact_person: this.quotation.new_contact_person,
        title: this.quotation.title,
        admin_note_id: this.quotation.admin_note_id,
        client_note_id: this.quotation.client_note_id,
        description: this.quotation.description,
        rating: this.quotation.rating,
        reference: this.quotation.reference,
        sales_person: this.quotation.sales_person,
        sales: this.quotation.sales,
        prepared_by: this.quotation.prepared_by,
        prepared_by_id: this.quotation.prepared_by_id,
        admin_notify: this.quotation.admin_notify,
        customer_notify: this.quotation.customer_notify,
        discount_value: this.quotation.discount_value,
        discount_type: this.quotation.discount_type,
        is_taxed: this.quotation.is_taxed,
        adjustment: this.quotation.adjustment,
        term_conditions: this.quotation.term_conditions,
        client_note: this.quotation.client_note,
        admin_note: this.quotation.admin_note,
        attachments: this.quotation_attachments,

        packages: this.ltxPackage,
        
        ltx_calculation: this.ltxCalculation,
        ltx_lineitem: this.ltxLineItem,
        ltx_childlineitem: this.ltxChildLineItem,
        ltx_level2childlineitem: this.ltxLevel2ChildLineItem,

        package: this.package_id,
        duplicate: this.duplicate_id,
        "is-variation": this.quotation.is_variation,
        "is-package": this.lodash.toSafeInteger(this.isPackage),
        supplier_cost: this.total_supplier_cost,
        profit: this.total_profit,
        quotation_markup: (this.sub_total / this.total_supplier_cost - 1) * 100,
        quotation_profit: (this.total_profit / this.sub_total) * 100,
      };

      let REQUEST_TYPE = POST;
      let REQUEST_URL = "pre-quotation";
      if (this.quotation_id && this.quotation_id > 0) {
        REQUEST_TYPE = PUT;
        REQUEST_URL = "pre-quotation/" + this.quotation_id;
      }

      this.$store.dispatch(REQUEST_TYPE, {
          url: REQUEST_URL,
          data: request,
        })
        .then((response) => {
          this.back_force = true;
          this.quotation_id = ObjectPath.get(response, "data.id", 0);
          this.$nextTick(() => {
            this.$router.replace(
              this.getDefaultRoute("pre.quotation.detail", {
                params: { id: this.quotation_id },
              })
            );
          });
        })
        .catch((error) => {
          this.logError(error);
        })
        .finally(() => {
          this.form_loading = false;
        });
    },
  },
  mounted() {
    this.itemInstance?.init();

    this.get_attributes();

    if (!this.quotation_id) {
      this.quotation_id = ObjectPath.get(this.$route, "params.id", 0);
    }

    if (this.is_duplicate) {
      this.get_duplicate(this.duplicate_id);
    }

    const page_title = this.get_title();

    this.$store.dispatch(SET_BREADCRUMB, [
      { title: page_title },
      { title: "Create" },
    ]);

    const route_name = ObjectPath.get(this.$route, "name", null);

    if (route_name == "admin.pre.quotation.update") {
      this.form_loading = true;
      this.get_quotation(this.quotation_id);
      this.update_mode = true;
    }
  },
  beforeMount() {
    this.today_date = moment().format("YYYY-MM-DD");

    this.quotation_id = ObjectPath.get(this.$route, "params.id", 0);
    this.is_duplicate = ObjectPath.get(this.$route, "query.is-duplicate", 0);
    this.duplicate_id = ObjectPath.get(this.$route, "query.duplicate", 0);
    this.prospect_id = ObjectPath.get(this.$route, "query.prospect", 0);
    if (!this.prospect_id) {
      this.back_force = true;
      this.goBack();
    }
  },
  created() {
    this.$store.commit(CLEAR_LINE_ITEM);
    this.itemInstance = new ManageLineItem();
  },
  computed: {
    ...mapGetters(["layoutConfig", "ltxCalculation", "ltxPackage", "ltxLineItem", "ltxChildLineItem", "ltxLevel2ChildLineItem", "globalLoading"]),
    loaderLogo() {
      return process.env.VUE_APP_BASE_URL + this.layoutConfig("loader.logo");
    },
  },
  beforeRouteLeave(to, from, next) {
    const _this = this;
    if (_this.back_force) {
      next();
    } else {
      _this.route_prevent_dialog = true;
      PreventDialogEventBus.$on("stay-on-page", function () {
        _this.route_prevent_dialog = false;
        next(false);
      });
      PreventDialogEventBus.$on("leave-page", function () {
        _this.route_prevent_dialog = false;
        next();
      });
    }
  },
};
</script>
